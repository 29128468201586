import React, { Fragment } from 'react';

import Layout from '../App/components/layout';
import PasswordForgetForm from '../components/PasswordForget';
import SEO from "../App/components/seo"

const PasswordForgetPage = () => (
  <Fragment>
    <div className="wrapper maxWidth">
    <h1>Password Recovery</h1>
    <p>Forgot your password? Enter your registered email address to reset your password.</p>
    </div>
    <PasswordForgetForm />
  </Fragment>
);

export default () => (
  <Layout>
    <SEO title="" />
    <PasswordForgetPage />
  </Layout>
);
